import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import MainBanner from "../components/Home/MainBanner"
import ProgramManagement from "../components/Home/ProgramManagement"
import ComplianceAudit from "../components/Home/ComplianceAudit"
import OurFeatures from "../components/Home/OurFeatures"
import IntegrationTemplates from "../components/Home/IntegrationTemplates"

const Index = () => {
    return (
        <Layout>
            <Navbar />
            <MainBanner />
            <ProgramManagement />
            <ComplianceAudit />
            <OurFeatures />
            <IntegrationTemplates />
            <Footer />
        </Layout>
    );
}

export default Index