import React from 'react'
import ReactWOW from 'react-wow'
import screen from '../../assets/images/home/compiance-withphone.png'
import icon1 from '../../assets/images/home/compliance-icon-1.png'
import icon2 from '../../assets/images/home/compliance-icon-2.png'
import icon3 from '../../assets/images/home/compliance-icon-3.png'


const ComplianceAudit = () => {
    return (
        <section className="overview-area ptb-50 pt-100">
            <div className="container">
                

                {/* Right Image Style */}
                <div className="overview-box bg-green-blob-r">
                    <div className="overview-content">
                        <div className="content">
                            {/* <span className="sub-title">Agility</span> */}
                            <h2>Compliance and Audit</h2>
                            <p>Agility helps keep track of your security controls and compliance status</p>

                            <ul className="features-list pb-70">
                                <li><span><img src={icon1} alt="features" /> Track non-compliant controls and remediation activities</span></li>
                                <li><span><img src={icon2} alt="features" /> Conduct evidence gathering and audits</span></li>
                                <li><span><img src={icon3} alt="features" /> View compliance dashboards by program and certification</span></li>
                            </ul>
                            
                        </div>
                        <ReactWOW delay='.1s' animation='fadeInRight'>
                            <a className="read-more-btn" href="/contact">Request Demo <i className="flaticon-right"></i></a>
                        </ReactWOW>
                    </div>

                    <div className="overview-image">
                          <ReactWOW delay='.5s' animation='fadeInRight'>
                                <div className="image">
                                    <img src={screen} alt="features" /> 
                                </div>
                        </ReactWOW>
                    </div>
                </div>
                {/* End Right Image Style */}

             
  
            </div>
        </section>
    )
}

export default ComplianceAudit