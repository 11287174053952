import React from 'react'
import dashboards from '../../assets/images/icons/icon-dashboard.png'
import risk from '../../assets/images/icons/icon-risk.png'
import compliance from '../../assets/images/icons/icon-awareness.png'
import workflow from '../../assets/images/icons/icon-testing.png'
import policy from '../../assets/images/icons/icon-governance.png'
import programs from '../../assets/images/icons/icon-cloud-security.png'
import audit from '../../assets/images/icons/icon-incidents.png'
import roadmap from '../../assets/images/icons/icon-assessment.png'

const OurFeatures = () => {
    return (
        <section className="services-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
       

                    <h2>Agility Features</h2>
                    {/* <p>Everything you need to maintain compliance</p> */}
                </div>

                <div className="row">
                       <div className="col-lg-3 col-sm-4">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={dashboards} style={{maxWidth: 80}} alt="feature" />
                            </div>
                            <p>Compliance Dashboards</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={risk} style={{maxWidth: 80}} alt="feature" />
                            </div>
                            <p>Risk Assessment</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-4">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={compliance}  style={{maxWidth: 80}} alt="feature" />
                            </div>
                             <p>Compliance Activity Scheduler</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-sm-4">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={workflow}  style={{maxWidth: 80}} alt="feature" />
                            </div>
                              <p>Document Status and Workflow</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-sm-4">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={policy}   style={{maxWidth: 80}} alt="feature" />
                            </div>
                              <p>Pre-built compliance policy and procedure templates</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-3 col-sm-4">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={programs}  style={{maxWidth: 80}} alt="feature" />
                            </div>
                              <p>Supports multiple compliance programs</p>
                        </div>
                    </div>
                    
                  
                     <div className="col-lg-3 col-sm-3">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={audit}  style={{maxWidth: 80}} alt="feature" />
                            </div>
                              <p>Audit and evidence gathering</p>
                        </div>
                    </div>
                     <div className="col-lg-3 col-sm-3">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={roadmap}  style={{maxWidth: 80}} alt="feature" />
                            </div>
                              <p>Project tracking and roadmaps</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurFeatures