import React from 'react'
// import {Link} from 'gatsby'
import ReactWOW from 'react-wow'
import screen from '../../assets/images/home/pg-mgmt-screen.png'
// import grid from '../../assets/images/home/pg-mgmt-bg-grid.png'
import icon1 from '../../assets/images/home/pg-mgmt-icon-1.png'
import icon2 from '../../assets/images/home/pg-mgmt-icon-2.png'
import icon3 from '../../assets/images/home/pg-mgmt-icon-3.png'
import icon4 from '../../assets/images/icons/icon-sol1.png'
import icon5 from '../../assets/images/icons/icon-sol2.png'
import icon6 from '../../assets/images/icons/icon-sol3.png'
import icon7 from '../../assets/images/icons/icon-sol4.png'


const ProgramManagement = () => {
    return (
        <section className="overview-area ptb-50 pt-70">
            <div className="container">
                <div className="section-title">
 
                    <h2>Built on Atlassian</h2>
              
                </div>
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-solutions-box">
                          <div className="icon">
                               <img src={icon4} style={{width: 100}} alt="features" />
                            </div> 
                   
                            <p>Integrated with the Atlassian tools</p>

                        
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-solutions-box">
                        <div className="icon">
                             <img src={icon5} style={{width: 100}} alt="features" />
                        </div>

                            <p>Supports multiple compliance programs, including ISO27001, SOC2, FEDRAMP, PCI, HIPAA, GDPR, CCPA and more.</p>
                            
                        
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 offset-lg-0 ">
                        <div className="single-solutions-box">
                            <div className="icon">
                                 <img src={icon6} style={{width: 100}} alt="features" />
                            </div>

             
                            <p>Pre-loaded Jira &amp; Confluence security templates ready for your team</p>
                            
                          
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 offset-lg-0 ">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={icon7}  style={{width: 100}}alt="features" />
                            </div>

                

                            <p>Easy to deploy and setup, quickly start managing your security and compliance program</p>
                            
                          
                        </div>
                    </div>
                </div>
          
                <div className="overview-box bg-green-blob-l">
              
                        <div className="overview-image">
                         <div className="overview-image-grid"></div>
                            <ReactWOW delay='.5s' animation='fadeInLeft'>
                                <div className="image">
                                    <img src={screen} alt="features" />
                                </div>
                            </ReactWOW>
                    </div>
                    
                    <div className="overview-content">
                        <div className="content right-content">
                            <span className="sub-title">Agility</span>
                            <h2>Information Security Management</h2>
                            {/* <p>Define and manage your security program and compliance objectives</p> */}



                            <ul className="features-list pb-70">
                                <li><span><img src={icon1} alt="features" /> Adopt pre-built ISO27001 based policies on Confluence</span></li>
                                <li><span><img src={icon2} alt="features" /> Implement ISO27001 &amp; SOC2 requirements</span></li>
                                <li><span><img src={icon3} alt="features" /> Save time, add other compliance initiatives</span></li>
                            </ul>
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <a className="read-more-btn" href="/contact">Request Demo <i className="flaticon-right"></i></a>
                            </ReactWOW>
                        </div>
                    </div>
                </div>
                {/* End Left Image Style */}

               
            </div>
        </section>
    )
}

export default ProgramManagement